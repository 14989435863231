import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../customComponents/CustomButton";
import { setHasLoaded, setSelectedRestaurant } from "../../../../actions_reducers/actions/user.action";
import { useDispatch } from "react-redux";
import { updateArianneThread } from "../../../arianneThread/ArianneThread";
import { setCurrentPage } from "../../../../actions_reducers/actions/navigation.action";
import CustomScrollDiv from "../../../customComponents/CustomScrollBar";

const RestaurantsList = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const updateSelectedRestaurant = (e) => {
        updateArianneThread("dashboard", props.arianne, dispatch, "");
        dispatch(setCurrentPage('dashboard'));
        dispatch(setHasLoaded(false));
        dispatch(setSelectedRestaurant(e.target.id))
    }
    const getColorEvo = (value) => {
        if (parseFloat(value) < 0) return '#567A60'
        else if (parseFloat(value) > 0) return '#B5243B'
        else return '#666666'
    }
    const getSignEvo = (value) => {
        if (value > 0) return '+'
        else if (value < 0) return '-'
        else return ''
    }

    const getEvo = (restaurant, values) => {
        let massConvIniRest = values.a1ConvRest;
        let massConvMaxRest = values.a4ConvRest > 0 ? values.a4ConvRest : values.a3ConvRest > 0 ? values.a3ConvRest : values.a2ConvRest;
        let massConvIniKitch = values.a1ConvKitch;
        let massConvMaxKitch = values.a4ConvKitch > 0 ? values.a4ConvKitch : values.a3ConvKitch > 0 ? values.a3ConvKitch : values.a2ConvKitch;

        let massConvMaxTot = massConvMaxRest + massConvMaxKitch;
        let massConvIniTot = massConvIniRest + massConvIniKitch;
        let evoRest = massConvIniRest !== 0 ? getSignEvo((massConvMaxRest - massConvIniRest) / massConvIniRest * 100) + Math.abs((massConvMaxRest - massConvIniRest) / massConvIniRest * 100).toFixed(0) + " %" : "0 %";
        let evoKitch = massConvIniKitch !== 0 ? getSignEvo((massConvMaxKitch - massConvIniKitch) / massConvIniKitch * 100) + Math.abs((massConvMaxKitch - massConvIniKitch) / massConvIniKitch * 100).toFixed(0) + " %" : "0 %";
        let evoTot = getSignEvo((massConvMaxTot - massConvIniTot) / massConvIniTot * 100) + Math.abs((massConvMaxTot - massConvIniTot) / massConvIniTot * 100).toFixed(0) + " %";

        return {
            evoTot: evoTot === "NaN %" ? "0 %" : evoTot,
            evoRest: evoRest === "NaN %" ? "0 %" : evoRest,
            evoKitch: evoKitch === "NaN %" ? "0 %" : evoKitch,
            colorTot: getColorEvo((massConvMaxTot - massConvIniTot) / massConvIniTot * 100),
            colorKitch: getColorEvo((massConvMaxKitch - massConvIniKitch) / massConvIniKitch * 100),
            colorRest: getColorEvo((massConvMaxRest - massConvIniRest) / massConvIniRest * 100),

        }
    }
    const getEvoRow = (restaurant, values) => {
        let evo = getEvo(restaurant, values);
        if (props.shownType === "detailed")
            return (
                <div style={{ width: "100%", height: "100%" }}>
                    <div style={{ width: "100%", height: "32%", display: "flex", flexDirection: 'row' }}>
                        <div id={"evoRest" + restaurant.restaurant_name} style={{ height: "100%", width: "50%", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                            {t('dataTypes.restaurant')}
                        </div>
                        <div style={{ height: "100%", width: "50%", display: "flex", alignItems: "center", justifyContent: "center", color: evo.colorRest }}>
                            {evo.evoRest}
                        </div>


                    </div>
                    <div style={{ height: "2%" }} />
                    <div id={"evoKitch" + restaurant.restaurant_name} style={{ width: "100%", height: "32%", display: "flex", flexDirection: 'row' }}>
                        <div style={{ height: "100%", width: "50%", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                            {t('dataTypes.kitchen')}
                        </div>
                        <div style={{ height: "100%", width: "50%", display: "flex", alignItems: "center", justifyContent: "center", color: evo.colorKitch }}>
                            {evo.evoKitch}
                        </div>


                    </div>
                    <div style={{ height: "2%" }} />
                    <div style={{ width: "100%", height: "32%", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                        <div style={{ height: "100%", width: "50%", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                            {t('dataTypes.total')}
                        </div>
                        <div style={{ height: "100%", width: "50%", display: "flex", alignItems: "center", justifyContent: "center", color: evo.colorTot }}>
                            {evo.evoTot}
                        </div>
                    </div>
                </div >
            )
        else if (props.shownType === "detailedDays")
            return (
                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: 'column' }}>
                    <div style={{ width: "100%", height: "32%" }}>
                        <div style={{ height: "34%", display: "flex", flexDirection: "row" }}>
                            <div style={{ height: "100%", width: "50%", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                {t('dataTypes.restaurant')}
                            </div>
                            <div style={{ height: "100%", width: "50%", display: "flex", alignItems: "center", justifyContent: "center", color: evo.colorRest }}>
                                {evo.evoRest}
                            </div>

                        </div>
                        <div style={{ height: "33%", width: "100%" }}>
                            Nombre de jours
                        </div>
                        <div style={{ height: "33%", width: "100%" }}>
                            Nombre de plateaux
                        </div>

                    </div>
                    <div style={{ height: "2%" }} />
                    <div style={{ width: "100%", height: "32%" }}>
                        <div style={{ height: "34%", display: "flex", flexDirection: "row" }}>
                            <div style={{ height: "100%", width: "50%", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                {t('dataTypes.kitchen')}
                            </div>
                            <div style={{ height: "100%", width: "50%", display: "flex", alignItems: "center", justifyContent: "center", color: evo.colorKitch }}>
                                {evo.evoKitch}
                            </div>

                        </div>
                        <div style={{ height: "33%", width: "100%" }}>
                            Nombre de jours
                        </div>
                        <div style={{ height: "33%", width: "100%" }}>
                            Nombre de plateaux
                        </div>

                    </div>
                    <div style={{ height: "2%" }} />
                    <div style={{ width: "100%", height: "32%", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                        <div style={{ height: "100%", width: "50%", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                            {t('dataTypes.total')}
                        </div>
                        <div style={{ height: "100%", width: "50%", display: "flex", alignItems: "center", justifyContent: "center", color: evo.colorTot }}>
                            {evo.evoTot}
                        </div>
                    </div>
                </div >
            )
        else if (props.shownType === "restaurant")
            return (
                <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", color: evo.colorRest }}>
                    {evo.evoRest}
                </div>
            )
        else if (props.shownType === "kitchen")
            return (
                <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", color: evo.colorKitch }}>
                    {evo.evoKitch}
                </div>
            )
        else if (props.shownType == "total")
            return (
                <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", color: evo.colorTot }}>
                    {evo.evoTot}
                </div>
            )
    }
    const getSingleValue = (analysis) => {
        switch (props.selectedDataType) {
            case 'mass':
                return (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)).toFixed(0)
            case 'cost':
                return (parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost)).toFixed(0)
            case 'co2':
                return (parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2)).toFixed(0)
            default:
                return (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)).toFixed(0)
        }
    }
    const getSingleValueType = (value) => {
        if (value !== 0 && value !== '...' && value !== '......' && value !== "...0" && value)
            switch (props.selectedDataType) {
                case 'mass':
                    return t('mass', { value: { "mass": value, "unit": "kg", fixed: props.shownType === "detailedDays" ? 1 : 0 } })
                case 'cost':
                    return t('currency', { "value": { "cost": value, "fixed": props.shownType === "detailedDays" ? 1 : 0 } })
                case 'co2':
                    return t('mass', { value: { "mass": value, "unit": "kg", "fixed": props.shownType === "detailedDays" ? 1 : 0 } })
                default:
                    return t('mass', { value: { "mass": value, "unit": "kg", "fixed": props.shownType === "detailedDays" ? 1 : 0 } })
            }
        else if (value === '...' || value === '......' || value === "...0" || !value) return '...'
        else return ''
    }
    const getSingleValueConvType = (value) => {
        if (value !== 0 && value !== '...' && value !== '......' && value !== "...0")
            switch (props.selectedDataType) {
                case 'mass':
                    return t('mass', { value: { "mass": value, "unit": "g" } })
                case 'cost':
                    return t('currency', { "value": { "cost": value, "fixed": 2 } })
                case 'co2':
                    return t('mass', { value: { "mass": value, "unit": "g" } })
                default:
                    return t('mass', { value: { "mass": value, "unit": "g" } })
            }
        else if (value === '...' || value === '......' || value === "...0") return '...'
        else return ''
    }

    const getValueRow = (valueTot, valueRest, valueKitch, type, daysRest, daysKitch, traysRest, traysKitch) => {
        if (props.shownType === "detailed")
            return (
                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: 'column' }}>
                    <div style={{ width: "100%", height: "32%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        {type === "conv" ? getSingleValueConvType(valueRest) : valueRest !== 0 && getSingleValueType(valueRest)}
                    </div>
                    <div style={{ height: "2%" }} />
                    <div style={{ width: "100%", height: "32%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        {type === "conv" ? getSingleValueConvType(valueKitch) : valueKitch !== 0 && getSingleValueType(valueKitch)}
                    </div>
                    <div style={{ height: "2%" }} />
                    <div style={{ width: "100%", height: "32%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {type === "conv" ? getSingleValueConvType(valueTot) : valueTot !== 0 && getSingleValueType(valueTot)}
                    </div>
                </div>
            )
        else if (props.shownType === "detailedDays")
            return (
                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: 'column' }}>
                    <div style={{ width: "100%", height: "32%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ height: "34%" }}>{type === "conv" ? getSingleValueConvType(valueRest) : valueRest !== 0 && getSingleValueType(valueRest)}</div>
                        <div style={{ height: "33%" }}>{daysRest !== 0 && daysRest !== "..." && daysRest}</div>
                        <div style={{ height: "33%" }}>{traysRest !== 0 && traysRest !== "..." && traysRest}</div>
                    </div>
                    <div style={{ height: "2%" }} />
                    <div style={{ width: "100%", height: "32%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ height: "34%" }}>{type === "conv" ? getSingleValueConvType(valueKitch) : valueKitch !== 0 && getSingleValueType(valueKitch)}</div>
                        <div style={{ height: "33%" }}>{daysKitch !== 0 && daysKitch !== "..." && daysKitch}</div>
                        <div style={{ height: "33%" }}>{traysKitch !== 0 && traysKitch !== "..." && traysKitch}</div>
                    </div>
                    <div style={{ height: "2%" }} />
                    <div style={{ width: "100%", height: "32%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {type === "conv" ? getSingleValueConvType(valueTot) : valueTot !== 0 && getSingleValueType(valueTot)}
                    </div>
                </div>
            )
        else if (props.shownType === "restaurant")
            return (
                <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {type === "conv" ? getSingleValueConvType(valueRest) : valueRest !== 0 && getSingleValueType(valueRest)}
                </div>
            )
        else if (props.shownType === "kitchen")
            return (
                <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {type === "conv" ? getSingleValueConvType(valueKitch) : valueKitch !== 0 && getSingleValueType(valueKitch)}
                </div>
            )
        else if (props.shownType == "total")
            return (
                <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {type === "conv" ? getSingleValueConvType(valueTot) : valueTot !== 0 && getSingleValueType(valueTot)}
                </div>
            )
    }
    const getMassConvValues = (analysis) => {
        switch (props.selectedDataType) {
            case 'mass':
                return analysis.trays > 0 ? (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / parseInt(analysis.trays) : 0
            case 'cost':
                return analysis.trays > 0 ? (parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost)) / parseInt(analysis.trays) : 0
            case 'co2':
                return analysis.trays > 0 ? (parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2)) / parseInt(analysis.trays) : 0
            default:
                return analysis.trays > 0 ? (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / parseInt(analysis.trays) : 0
        }
    }
    const getValuesConv = (analyzes, values, number, type) => {
        for (let analysis of JSON.parse(JSON.stringify(analyzes))) {
            if (analysis.analysisNumber === 1) {
                values["a1Conv" + type] = getMassConvValues(analysis);
                values["days1" + type] = analysis.nbDays;
                values["trays1" + type] = analysis.trays;
            }
            else if (analysis.analysisNumber === 2) {
                values["a2Conv" + type] = getMassConvValues(analysis);
                values["days2" + type] = analysis.nbDays;
                values["trays2" + type] = analysis.trays;
            }
            else if (analysis.analysisNumber === 3) {
                values["a3Conv" + type] = getMassConvValues(analysis);
                values["days3" + type] = analysis.nbDays;
                values["trays3" + type] = analysis.trays;
                values["trays3" + type] = analysis.trays;
            }
            else {
                if (number <= 4) {
                    if (analysis.analysisNumber === 3) {
                        values["a3Conv" + type] = getMassConvValues(analysis);
                        values["days3" + type] = analysis.nbDays;
                        values["trays3" + type] = analysis.trays;
                    }
                    else if (analysis.analysisNumber === 4) {
                        values["a4Conv" + type] = getMassConvValues(analysis);
                        values["days4" + type] = analysis.nbDays;
                        values["trays4" + type] = analysis.trays;
                    }
                }
                else {
                    if (analysis.analysisNumber === number - 1) {
                        values["a3Conv" + type] = getMassConvValues(analysis);
                        values["days3" + type] = analysis.nbDays;
                        values["trays3" + type] = analysis.trays;
                    }
                    else if (analysis.analysisNumber === number) {
                        values["a4Conv" + type] = getMassConvValues(analysis);
                        values["days4" + type] = analysis.nbDays;
                        values["trays4" + type] = analysis.trays;
                    }
                }
            }
        }
        return values;
    }
    const getValuesTot = (analyzes, values, number, type) => {
        for (let analysis of JSON.parse(JSON.stringify(analyzes))) {
            if (analysis.analysisNumber === 1) {
                values["a1" + type] = getSingleValue(analysis);
            }
            else if (analysis.analysisNumber === 2 && number <= 4) {
                values["a2" + type] = getSingleValue(analysis);
            }
            else if (analysis.analysisNumber === 2 && number > 4) {
                values["a2" + type] = '...';
                values.a2 = '...'
            }
            else {
                if (number <= 4) {
                    if (analysis.analysisNumber === 3) {
                        values["a3" + type] = getSingleValue(analysis);
                    }
                    else if (analysis.analysisNumber === 4) {
                        values["a4" + type] = getSingleValue(analysis);
                    }
                }
                else {
                    if (analysis.analysisNumber === number - 1) {
                        values["a3" + type] = getSingleValue(analysis);
                    }
                    else if (analysis.analysisNumber === number) {
                        values["a4" + type] = getSingleValue(analysis);
                    }
                }
            }
        }
        return values;
    }
    const getValues = (restaurant, number) => {
        let values = {
            a1ConvTot: 0,
            a2ConvTot: 0,
            a3ConvTot: 0,
            a4ConvTot: 0,
            a1ConvRest: 0,
            a2ConvRest: 0,
            a3ConvRest: 0,
            a4ConvRest: 0,
            a1ConvKitch: 0,
            a2ConvKitch: 0,
            a3ConvKitch: 0,
            a4ConvKitch: 0,
            days1Rest: 0,
            days2Rest: 0,
            days3Rest: 0,
            days4Rest: 0,
            days1Kitch: 0,
            days2Kitch: 0,
            days3Kitch: 0,
            days4Kitch: 0,
            trays1Rest: 0,
            trays2Rest: 0,
            trays3Rest: 0,
            trays4Rest: 0,
            trays1Kitch: 0,
            trays2Kitch: 0,
            trays3Kitch: 0,
            trays4Kitch: 0
        }
        let valuesTot = {
            a1Tot: 0,
            a2Tot: 0,
            a3Tot: 0,
            a4Tot: 0,
            a1Rest: 0,
            a2Rest: 0,
            a3Rest: 0,
            a4Rest: 0,
            a1Kitch: 0,
            a2Kitch: 0,
            a3Kitch: 0,
            a4Kitch: 0
        }
        if (restaurant.analyzes) {
            restaurant.analyzes.sort((a, b) => a.analysisNumber - b.analysisNumber);
            restaurant.analyzesKitchen.sort((a, b) => a.analysisNumber - b.analysisNumber);
            values = getValuesConv(restaurant.analyzes, values, number, "Rest");
            values = getValuesConv(restaurant.analyzesKitchen, values, number, "Kitch");

            values.a1ConvTot = values.a1ConvRest + values.a1ConvKitch;
            values.a2ConvTot = values.a2ConvRest + values.a2ConvKitch;
            values.a3ConvTot = values.a3ConvRest + values.a3ConvKitch;
            values.a4ConvTot = values.a4ConvRest + values.a4ConvKitch;

            valuesTot = getValuesTot(restaurant.analyzes, valuesTot, number, "Rest");
            valuesTot = getValuesTot(restaurant.analyzesKitchen, valuesTot, number, "Kitch");

            valuesTot.a1Tot = parseFloat(valuesTot.a1Rest) + parseFloat(valuesTot.a1Kitch);
            valuesTot.a2Tot = parseFloat(valuesTot.a2Rest) + parseFloat(valuesTot.a2Kitch);
            valuesTot.a3Tot = parseFloat(valuesTot.a3Rest) + parseFloat(valuesTot.a3Kitch);
            valuesTot.a4Tot = parseFloat(valuesTot.a4Rest) + parseFloat(valuesTot.a4Kitch);
        }
        let evo = getEvoRow(restaurant, values)
        return {
            color: evo.colorEvo,
            evo: evo,
            a1Conv: getValueRow(values.a1ConvTot, values.a1ConvRest, values.a1ConvKitch, "conv", values.days1Rest, values.days1Kitch, values.trays1Rest, values.trays1Kitch),
            a2Conv: getValueRow(values.a2ConvTot, values.a2ConvRest, values.a2ConvKitch, "conv", values.days2Rest, values.days2Kitch, values.trays2Rest, values.trays2Kitch),
            a3Conv: getValueRow(values.a3ConvTot, values.a3ConvRest, values.a3ConvKitch, "conv", values.days3Rest, values.days3Kitch, values.trays3Rest, values.trays3Kitch),
            a4Conv: getValueRow(values.a4ConvTot, values.a4ConvRest, values.a4ConvKitch, "conv", values.days4Rest, values.days4Kitch, values.trays4Rest, values.trays4Kitch),
            a1: getValueRow(valuesTot.a1Tot, valuesTot.a1Rest, valuesTot.a1Kitch, values.days1Rest, values.days1Kitch),
            a2: getValueRow(valuesTot.a2Tot, valuesTot.a2Rest, valuesTot.a2Kitch, values.days2Rest, values.days2Kitch),
            a3: getValueRow(valuesTot.a3Tot, valuesTot.a3Rest, valuesTot.a3Kitch, values.days3Rest, values.days3Kitch),
            a4: getValueRow(valuesTot.a4Tot, valuesTot.a4Rest, valuesTot.a4Kitch, values.days4Rest, values.days4Kitch),
            commentary: "A voir"
        }
    }

    const getNumberAnalyzes = () => {
        let number = 0;
        for (const analysis of props.clientAnalyzes) {
            if (analysis.analysisNumber > number) number = analysis.analysisNumber;
        }
        for (const analysis of props.clientKitchenAnalyzes) {
            if (analysis.analysisNumber > number) number = analysis.analysisNumber;
        }
        return number;
    }

    const getRestaurantList = (analyzes, restaurants, type) => {
        if (analyzes.length > 0) {
            for (const analysis of analyzes) {
                let restaurant_name = analysis.restaurant_name;
                let analysisTmp = {
                    analysisNumber: analysis.analysisNumber,
                    mass: analysis.mass,
                    cost: analysis.cost,
                    co2: analysis.co2,
                    inevitableMass: analysis.inevitableMass,
                    inevitableCost: analysis.inevitableCost,
                    inevitableCo2: analysis.inevitableCo2,
                    trays: analysis.trays,
                    nbDays: analysis.dates.length
                }
                let index = restaurants.findIndex(a => a.restaurant_name === restaurant_name);
                if (index === -1) {
                    if (type === "restaurant") {
                        let newRestaurant = {
                            restaurant_name: restaurant_name,
                            analyzes: [analysisTmp],
                            analyzesKitchen: []
                        }
                        restaurants.push(newRestaurant);
                    }
                    else {
                        let newRestaurant = {
                            restaurant_name: restaurant_name,
                            analyzesKitchen: [analysisTmp],
                            analyzes: []
                        }
                        restaurants.push(newRestaurant);
                    }
                }
                else {
                    if (type === "restaurant") {
                        restaurants[index].analyzes.push(analysisTmp);
                    }
                    else restaurants[index].analyzesKitchen.push(analysisTmp);
                }
            }
        }
        return restaurants;
    }
    const getRowsHeight = () => {
        if (props.shownType === "detailed") return "20%"
        else if (props.shownType === "detailedDays") return "30%"
        else return "10%"
    }
    const getName = (restaurant_name) => {
        let restaurant = props.displayNames.find(a => a.restaurant_name === restaurant_name);
        if (restaurant && restaurant.value) return restaurant.value;
        else return restaurant_name;
    }
    const getRows = () => {
        let number = getNumberAnalyzes();
        let restaurants = []
        if (props.shownType !== "kitchen")
            restaurants = getRestaurantList(props.clientAnalyzes, restaurants, "restaurant");
        if (props.shownType !== "restaurant")
            restaurants = getRestaurantList(props.clientKitchenAnalyzes, restaurants, "kitchen")
        let result = [];
        restaurants.sort((a, b) => a.restaurant_name.localeCompare(b.restaurant_name))
        for (const restaurant of props.displayNames) {
            if (!restaurants.find(a => a.restaurant_name === restaurant.restaurant_name))
                restaurants.push({
                    restaurant_name: restaurant.restaurant_name
                })
        }
        for (const [index, restaurant] of restaurants.entries()) {
            let values = getValues(restaurant, number);
            result.push(
                <div key={index} style={{ width: "100%", height: getRowsHeight(), flexDirection: "row", display: "flex", color: "#666666", backgroundColor: index % 2 === 0 && "#F0F0F0" }}>
                    <div style={{ width: "22%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButton
                            id={restaurant.restaurant_name}
                            value={getName(restaurant.restaurant_name)}
                            width={"80%"}
                            height={props.shownType === "detailed" ? "5vh" : "5vh"}
                            fontSize={"0.70vw"}
                            paddingLeft={"1%"}
                            paddingRight={"1%"}
                            backgroundColor={"#9CCEBE"}
                            color={"#fff"}
                            boxShadow={'none'}
                            onClick={updateSelectedRestaurant} />

                    </div>
                    <div style={{ width: "14%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", color: values.color, borderRight: "1px solid #949494" }}>
                        {values.evo}
                    </div>
                    <div style={{ width: "8%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {values.a1Conv}
                    </div>
                    <div style={{ width: "8%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {values.a2Conv}
                    </div>
                    <div style={{ width: "8%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {values.a3Conv}
                    </div>
                    <div style={{ width: "8%", display: "flex", justifyContent: "center", alignItems: "center", borderRight: "1px solid #949494" }}>
                        {values.a4Conv}
                    </div>
                    <div style={{ width: "32%", display: "flex", flexDirection: "row" }}>
                        {props.selectedDataType !== "massByConv" &&
                            <>
                                <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {values.a1}
                                </div>
                                <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {values.a2}
                                </div>
                                <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {values.a3}
                                </div>
                                <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center", borderRight: "1px solid #949494" }}>
                                    {values.a4}
                                </div></>
                        }

                    </div>
                </div>
            )
        }
        return result;
    }
    const getHeadersAnalyzes = () => {
        let number = getNumberAnalyzes();

        if (number <= 4)
            return (
                <div style={{ width: "32%", display: "flex", flexDirection: "row", color: "#666666", fontWeight: 600, borderRight: "1px solid #949494" }}>
                    <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        A1
                    </div>
                    <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        A2
                    </div>
                    <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        A3
                    </div>
                    <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        A4
                    </div>
                </div>)
        else {
            let titles = [];
            for (var i = 1; i <= number; i++) {
                if (i === 1 || i === number - 1 || i === number)
                    titles.push("A" + i)
                else if (i === 2)
                    titles.push('...')
            }
            return (
                <div style={{ width: "32%", display: "flex", flexDirection: "row", color: "#666666", fontWeight: 600, borderRight: "1px solid #949494" }}>
                    {titles.map(item => {
                        return (
                            <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {item}
                            </div>
                        )
                    })}
                </div>)
        }
    }
    return (
        <CustomBlock width={"100%"} height={"100%"}>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "10%", color: "#666666", fontWeight: 600 }}>
                <div style={{ width: "22%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {t('table.restaurants')}
                </div>
                <div style={{ width: "14%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {t('table.evolution')}
                </div>
                <div style={{ width: "32%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {t(`table.massByConv`)}
                </div>
                <div style={{ width: "32%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {props.selectedDataType !== "massByConv" && t(`table.${props.selectedDataType}`)}
                </div>
                {/* <div style={{ width: "10%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {t(`table.commentary`)}
                </div> */}
            </div>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "5%", borderBottom: "1px solid #949494" }}>
                <div style={{ width: "8%" }} />
                <div style={{ width: "28%", borderRight: "1px solid #949494" }} />
                {
                    getHeadersAnalyzes()
                }

                {props.selectedDataType !== "massByConv" ?
                    getHeadersAnalyzes()
                    :
                    <div style={{ width: "32%", display: "flex", flexDirection: "row", color: "#666666", fontWeight: 600 }}></div>}
            </div>
            <div style={{ width: "100%", height: "85%" }}>
                <CustomScrollDiv update={Math.random()}>
                    {getRows()}
                </CustomScrollDiv>

            </div>
        </CustomBlock>
    )
}

export default RestaurantsList;
