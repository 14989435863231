export function setUserId(userId) {
    return {
        type: 'SET_USER_ID',
        userId: userId
    }
}

export function setToken(token) {
    return {
        type: 'SET_TOKEN',
        token: token
    }
}

export function setUserType(user_type) {
    return {
        type: 'SET_USER_TYPE',
        user_type: user_type
    }
}

export function setIsLoggedIn(is_logged_in) {
    return {
        type: 'SET_IS_LOGGED_IN',
        is_logged_in: is_logged_in
    }
}

export function setHasLoaded(has_loaded) {
    return {
        type: 'SET_HAS_LOADED',
        has_loaded: has_loaded
    }
}

export function setRestaurantList(restaurant_list) {
    return {
        type: 'SET_RESTAURANT_LIST',
        restaurant_list: restaurant_list
    }
}

export function setHasNoon(has_noon) {
    return {
        type: 'SET_HAS_NOON',
        has_noon: has_noon
    }
}

export function setHasEvening(has_evening) {
    return {
        type: 'SET_HAS_EVENING',
        has_evening: has_evening
    }
}

export function setSelectedHours(selected_hours) {
    return {
        type: 'SET_SELECTED_HOURS',
        selected_hours: selected_hours
    }
}
export function setMultipleCams(multiple_cams) {
    return {
        type: 'SET_MULTIPLE_CAMS',
        multiple_cams: multiple_cams
    }
}
export function setCams(cams) {
    return {
        type: 'SET_CAMS',
        cams: cams
    }
}
export function setSelectedCams(selected_cams) {
    return {
        type: 'SET_SELECTED_CAMS',
        selected_cams: selected_cams
    }
}
export function setHasKitchen(has_kitchen) {
    return {
        type: 'SET_HAS_KITCHEN',
        has_kitchen: has_kitchen
    }
}

export function setSelectedRestaurant(selected_restaurant) {
    return {
        type: 'SET_SELECTED_RESTAURANT',
        selected_restaurant: selected_restaurant
    }
}

export function setSelectedAnalysisDataType(selectedAnalysisDataType) {
    return {
        type: 'SET_SELECTED_ANALYSIS_DATA_TYPE',
        selectedAnalysisDataType: selectedAnalysisDataType
    }
}

export function setLogin(login) {
    return {
        type: 'SET_LOGIN',
        login: login
    }
}

export function setAverageGuests(averageGuests) {
    return {
        type: 'SET_AVERAGE_GUESTS',
        averageGuests: averageGuests
    }
}

export function setHasSynthesis(has_synthesis) {
    return {
        type: "SET_HAS_SYNTHESIS",
        has_synthesis: has_synthesis
    }
}
export function setHasRestaurant(has_restaurant) {
    return {
        type: "SET_HAS_RESTAURANT",
        has_restaurant: has_restaurant
    }
}
export function setClientAverageGuests(clientAverageGuests) {
    return {
        type: 'SET_CLIENT_AVERAGE_GUESTS',
        clientAverageGuests: clientAverageGuests
    }
}
export function setNumberWeeks(numberWeeks) {
    return {
        type: 'SET_NUMBER_WEEKS',
        numberWeeks: numberWeeks
    }
}
export function setDisplayName(displayName) {
    return {
        type: 'SET_DISPLAY_NAME',
        displayName: displayName
    }
}
export function setHasSurvey(hasSurvey) {
    return {
        type: "SET_HAS_SURVEY",
        hasSurvey: hasSurvey
    }
}
export function setLinkToSurvey(linkToSurvey) {
    return {
        type: 'SET_LINK_TO_SURVEY',
        linkToSurvey: linkToSurvey
    }
}
export function setSynthesisName(synthesisName) {
    return {
        type: 'SET_SYNTHESIS_NAME',
        synthesisName: synthesisName
    }
}
export function setHasSaturday(has_saturday) {
    return {
        type: 'SET_HAS_SATURDAY',
        has_saturday: has_saturday
    }
}
export function setHasSunday(has_sunday) {
    return {
        type: 'SET_HAS_SUNDAY',
        has_sunday: has_sunday
    }
}
export function setMassUnit(massUnit) {
    return {
        type: 'SET_MASS_UNIT',
        massUnit: massUnit
    }
}
export function setCostUnit(costUnit) {
    return {
        type: 'SET_COST_UNIT',
        costUnit: costUnit
    }
}
export function setRestaurantType(restaurant_type) {
    return {
        type: 'SET_RESTAURANT_TYPE',
        restaurant_type: restaurant_type
    }
}
export function setIsChatOpen(isChatOpen) {
    return {
        type: 'SET_IS_CHAT_OPEN',
        isChatOpen: isChatOpen
    }
}
export function softResetUser() {
    return {
        type: 'SOFT_RESET_USER'
    }
}
export function mediumResetUser() {
    return {
        type: 'MEDIUM_RESET_USER'
    }
}
export function hardResetUser() {
    return {
        type: 'HARD_RESET_USER'
    }
}