import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import './Dashboard.css';

import { getWasteByRestaurantAndAnalysis } from "../../../apiRoutes/analyzesDatas.routes";
import { setAllDatesSamplesBin, setAllObservations, setAnalyzesBaseDatas, setFoodList, setFoodListKitchen, setFoodValues, setInitialAnalyzes, setKitchenDatas, setSamplesBin, setTraysByDay, setWasteManagementMethod } from "../../../actions_reducers/actions/datas.action";
import { setIsAnalysisCut, setSelectedAnalysisNumber } from "../../../actions_reducers/actions/dashboard.action";
import { getObjectivesOfRestaurant } from "../../../apiRoutes/objectives.route";
import { setObjectives } from "../../../actions_reducers/actions/objectives.action";
import { setAverageGuests, setCams, setCostUnit, setDisplayName, setHasEvening, setHasKitchen, setHasLoaded, setHasNoon, setHasRestaurant, setHasSaturday, setHasSunday, setHasSurvey, setHasSynthesis, setLinkToSurvey, setMassUnit, setMultipleCams, setNumberWeeks, setRestaurantType, setSelectedCams, setSelectedHours, setSynthesisName } from "../../../actions_reducers/actions/user.action";
import { updateArianneThread } from "../../arianneThread/ArianneThread";
import DashboardAllAnalyzes from "./elementsAllAnalyzes/DashboardAllAnalyzes";
import DashboardAnalysisDetails from "./elementsAnalysisDetails/DashboardAnalysisDetails";
import { getFoodsOfRestaurant } from "../../../apiRoutes/food.routes";
import DashboardDay from "./elementsDay/DashboardDay";
import { getDatas, getKitchenDatas } from "../../customFunctions/data.function";
import { getInformationsRestaurant } from "../../../apiRoutes/informations.routes";
import { getNewTrays } from "../../../apiRoutes/newTrays.route";
import { updateDataForTrays } from "../../customFunctions/newTrays.function";
import { getKitchenWasteByAnalysis } from "../../../apiRoutes/analyzesDatasKitchen.routes";
import { getKitchenFoods } from "../../../apiRoutes/kitchenFood.routes";
import { getAllDatesSamplesBin, getSamplesBin } from "../../../apiRoutes/samplesBin.route";
import CustomLoading from "../../customComponents/CustomLoading";
import { getAverageGuests } from "../../../apiRoutes/onBoarding.routes";
import { getObservations } from "../../../apiRoutes/observations.routes";
import { setLanguage } from "../../../actions_reducers/actions/navigation.action";
import { useTranslation } from "react-i18next";
import { getNutritionValues } from "../../../apiRoutes/nutritionValues.route";

const Dashboard = (props) => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!props.userState.has_loaded && props.userState.selected_restaurant)
            setAnalysisValues()
    }, [props.userState.selected_restaurant, props.loadAllDatas, props.userState.has_loaded]);
    useEffect(() => {
        if (props.userState.has_loaded) {
            getDatas(props.analyzesBaseDatas, props.userState.selected_hours, props.userState.selected_cams, props.dashboardState, dispatch, props.userState.averageGuests);
            if (props.userState.has_kitchen) {
                getKitchenDatas(props.kitchenDatas, props.userState.selected_hours, props.dashboardState, dispatch, props.userState.averageGuests)
            }
        }
    }, [props.userState.selected_hours, props.userState.selected_cams, props.userState.has_loaded, props.userState.has_kitchen]);

    const setAnalysisValues = async () => {
        let restaurant_name = props.userState.selected_restaurant;
        let promises = await Promise.all([
            getInformationsRestaurant(restaurant_name),
            getWasteByRestaurantAndAnalysis(restaurant_name, props.loadAllDatas),
            getKitchenWasteByAnalysis(restaurant_name),
            getObjectivesOfRestaurant(restaurant_name),
            getFoodsOfRestaurant(restaurant_name),
            getNewTrays(restaurant_name),
            getKitchenFoods(restaurant_name),
            getSamplesBin(restaurant_name),
            getAverageGuests(restaurant_name),
            getObservations(restaurant_name),
            getNutritionValues(restaurant_name)
        ])

        let informations = promises[0];
        let analyzesTmp = promises[1];
        let kitchenValues = promises[2];
        let objectives = promises[3];
        let foods = promises[4];
        let newTrays = promises[5];
        let kitchenFoods = promises[6];
        let samplesBin = promises[7];
        let averageGuests = promises[8];
        let observations = promises[9];
        let nutritionValues = promises[10];
        let analyzes = analyzesTmp.analyzes.sort((a, b) => a.analysisNumber - b.analysisNumber);
        let dates = await getAllDatesSamplesBin(restaurant_name);
        dispatch(setAllDatesSamplesBin(dates));
        dispatch(setFoodValues(nutritionValues));
        dispatch(setInitialAnalyzes(JSON.parse(JSON.stringify(analyzesTmp.analyzes))));
        updateArianneThread('dashboard', props.arianne, dispatch);
        if (samplesBin.length > 0)
            dispatch(setSamplesBin(samplesBin.sort((a, b) => a.created_at.localeCompare(b.created_at))));
        dispatch(setKitchenDatas(kitchenValues.analyzes));
        if (informations.averageTrays)
            dispatch(setAverageGuests(informations.averageTrays))
        else if (averageGuests && averageGuests !== 0)
            dispatch(setAverageGuests(averageGuests))
        dispatch(setTraysByDay(newTrays));
        dispatch(setFoodList(foods));
        dispatch(setFoodListKitchen(kitchenFoods));
        dispatch(setAllObservations(observations));
        let updatedAnalyzes = updateDataForTrays(analyzes, newTrays, informations.multipleCams, informations.camsNames, restaurant_name);

        dispatch(setAnalyzesBaseDatas(updatedAnalyzes));
        dispatch(setObjectives(objectives));

        if (analyzes[analyzes.length - 1])
            dispatch(setSelectedAnalysisNumber(analyzes[analyzes.length - 1].analysisNumber))
        else if (kitchenValues.analyzes[kitchenValues.analyzes.length - 1])
            dispatch(setSelectedAnalysisNumber(kitchenValues.analyzes[kitchenValues.analyzes.length - 1].analysisNumber))
        updateInformations(informations, restaurant_name);
        dispatch(setHasLoaded(true));

        let isCutRestaurant = analyzesTmp.isCut;
        if (isCutRestaurant === true)
            dispatch(setIsAnalysisCut(true));
        else dispatch(setIsAnalysisCut(false))
    }
    const updateInformations = (informations, restaurant_name) => {
        let hours = ["total", "noon", "evening"];
        if (informations.noon) dispatch(setHasNoon(true));
        if (informations.evening) dispatch(setHasEvening(true));
        dispatch(setSelectedHours(hours));
        //if (props.userState.user_type !== "admin") {
            dispatch(setMassUnit(informations.massUnit))
            dispatch(setCostUnit(informations.costUnit))
        //}
        dispatch(setRestaurantType(informations.restaurantType))
        dispatch(setHasSurvey(informations.hasSurvey))
        dispatch(setLinkToSurvey(informations.linkToSurvey))
        dispatch(setMultipleCams(informations.multipleCams))
        dispatch(setHasRestaurant(informations.hasRestaurant))
        dispatch(setHasSynthesis(informations.hasSynthesis));
        dispatch(setNumberWeeks(informations.numberWeeksYear))
        dispatch(setDisplayName(informations.displayName));
        dispatch(setSynthesisName(informations.synthesisName))
        dispatch(setHasSaturday(informations.saturday));
        dispatch(setHasSunday(informations.sunday))
        let cams = [{
            camName: "total",
            displayName: "Total"
        }];
        if (informations.multipleCams) {
            for (const cam of informations.camsNames) {
                let newCam = {
                    camName: restaurant_name.toLowerCase() + cam.numberCam,
                    displayName: cam.nameCam
                }
                cams.push(newCam)
            }
        }
        dispatch(setCams(cams))
        dispatch(setSelectedCams(cams))
        dispatch(setHasKitchen(informations.hasKitchen))
        dispatch(setWasteManagementMethod(informations.wasteManagementMethod))
    }

    if (props.userState.has_loaded) {
        if (props.dashboardState.showedAnalysis === null) {
            return (
                <DashboardAllAnalyzes
                    isAnalysisCut={props.isAnalysisCut}
                    userState={props.userState}
                    dashboardState={props.dashboardState}
                    objectivesState={props.objectivesState}
                    analyzesDatas={props.analyzesDatas}
                    categories={props.categories}
                    extrapolatedAnalyzes={props.extrapolatedAnalyzes}
                    arianne={props.arianne}
                    kitchenAnalyzesDatas={props.kitchenAnalyzesDatas}
                    kitchenExtrapolatedAnalyzes={props.kitchenExtrapolatedAnalyzes}
                    has_kitchen={props.has_kitchen}
                    has_restaurant={props.has_restaurant}
                    has_synthesis={props.has_synthesis}
                />)
        }
        else if (props.dashboardState.selectedDay === null)
            return (
                <DashboardAnalysisDetails
                    selectedPicture={props.selectedPicture}
                    dashboardState={props.dashboardState}
                    analyzesBaseDatas={props.analyzesBaseDatas}
                    analyzesDatas={props.analyzesDatas}
                    extrapolatedAnalyzes={props.extrapolatedAnalyzes}
                    arianne={props.arianne}
                    analyzesTrays={props.analyzesTrays}
                    userState={props.userState}
                    pictures_list={props.pictures_list}
                    kitchenAnalyzesDatas={props.kitchenAnalyzesDatas}
                    kitchenExtrapolatedAnalyzes={props.kitchenExtrapolatedAnalyzes}
                    food_list={props.food_list}
                    has_kitchen={props.has_kitchen}
                    has_restaurant={props.has_restaurant}
                    food_list_kitchen={props.food_list_kitchen}
                    selectedImage={props.selectedImage}
                    weighIns={props.weighIns}
                    observations={props.observations}
                    has_synthesis={props.has_synthesis}
                    displayedPictures={props.displayedPictures} />
            );
        else return (
            <DashboardDay
                dashboardState={props.dashboardState}
                analyzesBaseDatas={props.analyzesBaseDatas}
                analyzesDatas={props.analyzesDatas}
                analyzesTrays={props.analyzesTrays}
                pictures_list={props.pictures_list}
                food_list={props.food_list}
                userState={props.userState}
                arianne={props.arianne}
                kitchenAnalyzesDatas={props.kitchenAnalyzesDatas}
                kitchenExtrapolatedAnalyzes={props.kitchenExtrapolatedAnalyzes}
                has_kitchen={props.has_kitchen}
                has_restaurant={props.has_restaurant}
                weighIns={props.weighIns}
                food_list_kitchen={props.food_list_kitchen}
                selectedImage={props.selectedImage} />
        )
    }
    else
        return (
            <div className="main-page-main-div">
                <CustomLoading />
            </div>
        )
}

function mapStateToProps(state) {
    return {
        userState: state.userReducer,
        dashboardState: state.dashboardReducer,
        objectivesState: state.objectivesReducer,
        analyzesDatas: state.datasReducer.analyzesDatas,
        categories: state.datasReducer.categories,
        extrapolatedAnalyzes: state.datasReducer.extrapolatedAnalyzes,
        analyzesBaseDatas: state.datasReducer.analyzesBaseDatas,
        arianne: state.navigationReducer.arianne,
        analyzesTrays: state.datasReducer.analyzesTrays,
        pictures_list: state.datasReducer.pictures_list,
        displayedPictures: state.picturesReducer.displayedPictures,
        food_list: state.datasReducer.food_list,
        kitchenDatas: state.datasReducer.kitchenDatas,
        kitchenAnalyzesDatas: state.datasReducer.kitchenAnalyzesDatas,
        kitchenExtrapolatedAnalyzes: state.datasReducer.kitchenExtrapolatedAnalyzes,
        has_kitchen: state.userReducer.has_kitchen,
        weighIns: state.datasReducer.weighIns,
        food_list_kitchen: state.datasReducer.food_list_kitchen,
        selectedImage: state.datasReducer.selected_image_samples_bin,
        selectedPicture: state.picturesReducer.selectedPicture,
        observations: state.datasReducer.all_observations,
        has_restaurant: state.userReducer.has_restaurant,
        has_synthesis: state.userReducer.has_synthesis,
        isAnalysisCut: state.dashboardReducer.isAnalysisCut,
        loadAllDatas: state.dashboardReducer.loadAllDatas
    };
}

export default connect(mapStateToProps)(Dashboard);
