import React, { useEffect, useState, useRef } from "react";
import CustomDropdown from "../../customComponents/CustomDropdown";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import 'moment/locale/fr';
import { setSelectedAnalysisAlerts, setSelectedDateAlerts } from "../../../actions_reducers/actions/alerts.action";

const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const AlertsTop = (props) => {
    const { t } = useTranslation();
    const dropdownDateRef = useRef(null);
    const dropdownAnalysisRef = useRef(null);

    const dispatch = useDispatch();
    const [daysList, setDaysList] = useState([]);

    useEffect(() => {
        getDaysDropdown()
    }, [props.selectedAnalysis]);

    const getDaysDropdown = () => {
        let result = [];
        let dates = [];
        let currentAnalyzes = JSON.parse(JSON.stringify(props.analyzes)).filter(a => a.analysisNumber === props.selectedAnalysis);
        for (const analysis of currentAnalyzes) {
            for (const day of analysis.days) {
                if (!dates.find(a => a === moment(day.date).format('DD-MM-YYYY')))
                    dates.push(moment(day.date).format("DD-MM-YYYY"))
            }
        }
        for (const date of props.dates) {
            let dateTmp = moment(date.date).format('DD-MM-YYYY');
            if (!result.find(a => a === dateTmp) && dates.find(a => a === dateTmp)) {
                let checked = true;
                if (props.uncheckedDates.find(a => a === date.date))
                    checked = false;
                result.push({ name: dateTmp, warning: !checked });
            }
        }
        result.sort((a, b) => moment(a.name, "DD-MM-YYYY").isBefore(moment(b.name, "DD-MM-YYYY")) ? -1 : 1);
        setDaysList(result);
        if (result.length > 0 && !result.find(a => a.name === moment(props.selectedDate).format("DD-MM-YYYY")))
            dispatch(setSelectedDateAlerts(t(`date`, { "value": { "date": moment(result[0].name, "DD-MM-YYYY").format("YYYY-MM-DD"), "format": "YYYY-MM-DD" } })))

    }
    const getDisplayedDropdownValue = () => {
        return t(`date`, { "value": { "date": moment(props.selectedDate).format("YYYY-MM-DD"), "format": "dddd DD MMMM YYYY" } });
    }
    const changeSelectedDay = (e) => {
        dispatch(setSelectedDateAlerts(moment(e.target.id, "DD-MM-YYYY").format("YYYY-MM-DD")))
    }
    const getDisplayedDropdownAnalyzesValue = () => {
        return t('components.analysisName', { analysisNumber: props.selectedAnalysis })
    }
    const changeSelectedAnalysis = (e) => {
        let value = e.target.id;
        let analysisNumber = parseInt(value.split(t('table.analysis'))[1]);
        if (analysisNumber !== props.selectedAnalysis)
            dispatch(setSelectedAnalysisAlerts(analysisNumber))
    }
    const getAnalyzes = () => {
        let result = [];
        let analyzes = JSON.parse(JSON.stringify(props.analyzes));
        console.log(props.analyzes)
        analyzes.sort((a, b) => b.analysisNumber - a.analysisNumber)
        for (const analysis of analyzes) {
            let name = t('components.analysisName', { analysisNumber: analysis.analysisNumber })
            if (!result.find(a => a === name))
                result.push(name)
        }
        return result
    }
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row" }}>
            <div ref={dropdownAnalysisRef} style={{ width: "25%", height: "100%" }}>

                <CustomDropdown width={"90%"} height={"80%"}
                    backgroundColor={'#68B4E3'}
                    displayedValue={getDisplayedDropdownAnalyzesValue()}
                    values={getAnalyzes()}
                    onChange={changeSelectedAnalysis}
                    maxHeightDropdown={getAnalyzes().length > 8 && "50vh"}
                    arrowColor={"#fff"}
                    color={"#fff"}
                    border={"1px solid #68B4E3"} />
            </div>
            <div ref={dropdownDateRef} style={{ width: "25%", height: "100%" }}>

                <CustomDropdown width={"90%"} height={"80%"}
                    backgroundColor={'#68B4E3'}
                    displayedValue={props.selectedDate ? getDisplayedDropdownValue() : "Pas d'alerte"}
                    selectedValue={t(`date`, { "value": { "date": props.selectedDate, "format": "DD-MM-YYYY" } })}
                    values={daysList}
                    onChange={changeSelectedDay}
                    maxHeightDropdown={"50vh"}
                    arrowColor={"#fff"}
                    color={"#fff"}
                    border={"1px solid #68B4E3"}
                    alert={true} />
            </div>
            <div style={{ width: "30%" }} />
            <div style={{ width: "5%" }} />
            <div style={{ width: "15%", height: "100%" }}>
            </div>
        </div>
    )
}

export default AlertsTop;