import React, { useState, useEffect, useRef } from 'react';
import { collection, doc, onSnapshot, addDoc, serverTimestamp, arrayUnion, getDocs, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import Firebase storage utilities
import { db, storage } from '../../../firebase'; // Import storage from firebase.js
import CustomScrollDiv from '../../customComponents/CustomScrollBar';
import { ReactComponent as Camera } from "../../../assets/camera.svg";
import { ReactComponent as Cross } from "../../../assets/cross.svg";
import { ReactComponent as CrossCircle } from "../../../assets/crossCircle.svg";
import { ReactComponent as Chat } from "../../../assets/chat.svg";
import { ReactComponent as SendIcon } from "../../../assets/sendIcon.svg";
import { ReactComponent as PictureChat } from "../../../assets/pictureChat.svg";
import { ReactComponent as Clip } from "../../../assets/clip.svg";
import { ReactComponent as KikleoChatIcon } from "../../../assets/kikleoChatIcon.svg";
import { ReactComponent as ChatCross } from "../../../assets/chatCross.svg";
import "./Chat.css";
import { useDispatch } from 'react-redux';
import { setIsChatOpen } from '../../../actions_reducers/actions/user.action';
import { useTranslation } from 'react-i18next';
import CustomBlock from '../../customComponents/CustomBlock';
import { sendAlertMessageChat } from '../../../apiRoutes/slackAlerts.routes';
import env from "react-dotenv";
const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);

function ClientChat({ chatId, userId, isChatOpen, login, restaurant_name, client_name }) {
  const dispatch = useDispatch();
  const textareaRef = useRef(null);
  const iconRef = useRef(null);
  const { t } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [imageFile, setImageFile] = useState(null); // Add state for the image file
  const [file, setFile] = useState(null); // Add state for the image file
  const [update, setUpdate] = useState(0);
  const [imageSrc, setImageSrc] = useState(null);
  const [unreadNumber, setUnreadNumber] = useState(0);
  const [initialHeight, setInitialHeight] = useState(0);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (textareaRef.current) {
      setInitialHeight(textareaRef.current.scrollHeight); // Set initial height of textarea
    }
  }, []);
  useEffect(() => {
    // Function to center the icon
    const centerIcon = () => {
      const textarea = textareaRef.current;
      const icon = iconRef.current;
      if (textarea && icon) {
        const containerHeight = textarea.clientHeight;
        const iconHeight = icon.getBoundingClientRect().height;

        // Calculate vertical and horizontal center
        const top = (containerHeight - iconHeight) / 2;

        icon.style.bottom = `${top}px`;
      }
    };

    // Center icon initially and on text change
    centerIcon();

    // Optional: Add a resize observer if you want to handle dynamic resizing more robustly
    const resizeObserver = new ResizeObserver(centerIcon);
    if (textareaRef.current) {
      resizeObserver.observe(textareaRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [newMessage, isChatOpen]);
  const handleInputChange = (e) => {
    const textarea = textareaRef.current;
    const newText = e.target.value;
    // Dynamically set height based on content
    textarea.style.height = "auto"; // Reset height to auto
    const newHeight = textarea.scrollHeight; // Get the new scroll height based on content

    // Set the height of the textarea to the new scroll height
    textarea.style.height = `${newHeight}px`;

    // Update the text state
    setNewMessage(newText);
  };
  const markMessagesAsRead = async (chatId, userId) => {
    const chatRef = doc(db, 'chats', chatId);
    const messagesCollection = collection(chatRef, 'messages');

    const messagesSnapshot = await getDocs(messagesCollection);
    if (isChatOpen)
      messagesSnapshot.forEach(async (messageDoc) => {
        const messageData = messageDoc.data();

        // If the message was not read by the current user
        if (!messageData.readBy.includes(userId)) {
          // Add the user ID to the readBy array
          await updateDoc(messageDoc.ref, {
            readBy: arrayUnion(userId)
          });
        }
      });
  };
  useEffect(() => {
    const chatRef = doc(db, 'chats', chatId);
    const messagesCollection = collection(chatRef, 'messages');
    const unsubscribe = onSnapshot(messagesCollection, (snapshot) => {
      const messages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      messages.sort((a, b) => a.timestamp && b.timestamp && a.timestamp.seconds - b.timestamp.seconds)
      let unread = 0;
      for (const message of messages) {
        if (!message.readBy.find(a => a === userId))
          unread += 1;
      }
      setUnreadNumber(unread)
      setMessages(messages);
      markMessagesAsRead(chatId, userId);
      setUpdate(old => old + 1)
    });

    return () => unsubscribe();
  }, [chatId, isChatOpen]);
  useEffect(() => {
    const createChatIfNotExist = async () => {
      const chatRef = doc(db, 'chats', chatId);
      const docSnapshot = await getDoc(chatRef);
      if (!docSnapshot.exists()) {
        // Document does not exist; create it with some initial data
        await setDoc(chatRef, {
          createdAt: serverTimestamp(),
          lastMessage: t('longTexts.firstMessageChat'),
          lastMessageTimestamp: serverTimestamp(),
          lastMessageSenderId: "Support",
          status: "closed",
          restaurant_name: (restaurant_name && !client_name) && restaurant_name,
          client_name: client_name && client_name
        });
        await addDoc(collection(chatRef, 'messages'), {
          text: t('longTexts.firstMessageChat'),
          senderId: "Support",
          timestamp: serverTimestamp(),
          readBy: [], // Initialize as an empty array
        });
      }
    }
    createChatIfNotExist()
  }, [])
  const uploadFile = async (file) => {
    const fileRef = ref(storage, `chat_files/${Date.now()}_${file.name}`);
    const uploadResult = await uploadBytes(fileRef, file);
    const fileUrl = await getDownloadURL(uploadResult.ref);
    return fileUrl;
  };
  // Function to handle sending a message
  const sendMessage = async () => {
    if (newMessage.trim() || imageFile || file) {
      let imageUrl = '';
      let fileUrl = '';

      const chatRef = doc(db, 'chats', chatId);
      const docSnapshot = await getDoc(chatRef);
      if (!docSnapshot.exists()) {
        // Document does not exist; create it with some initial data
        await setDoc(chatRef, {
          createdAt: serverTimestamp(),
          lastMessage: newMessage || (imageFile && 'Image') || (file && "File"),
          lastMessageTimestamp: serverTimestamp(),
          lastMessageSenderId: userId,
          status: "open",
          restaurant_name: (restaurant_name && !client_name) && restaurant_name,
          client_name: client_name && client_name
        });
      }
      // If a file is selected, upload it to Firebase Storage
      if (file) {
        fileUrl = await uploadFile(file);
        await addDoc(collection(chatRef, 'messages'), {
          text: newMessage,
          senderId: userId,
          fileUrl: fileUrl, // Save file URL to Firestore
          fileName: file.name, // Save the file name
          fileType: file.type, // Save the file type (e.g., 'application/pdf')
          timestamp: serverTimestamp(),
          readBy: [], // Initialize as an empty array
        });
      }

      // If an image is selected, upload it to Firebase Storage
      if (imageFile) {
        const imageRef = ref(storage, `chat_images/${Date.now()}_${imageFile.name}`);
        const uploadResult = await uploadBytes(imageRef, imageFile);
        imageUrl = await getDownloadURL(uploadResult.ref);
        await addDoc(collection(chatRef, 'messages'), {
          text: newMessage || "Image",
          senderId: userId,
          imageUrl: imageUrl, // Save image URL to Firestore if an image was uploaded
          timestamp: serverTimestamp(),
          readBy: [], // Initialize as an empty array
        });
      }
      if (!imageFile && !file) {
        await addDoc(collection(chatRef, 'messages'), {
          text: newMessage,
          senderId: userId,
          timestamp: serverTimestamp(),
          readBy: [], // Initialize as an empty array
        });

      }
      // Update the parent chat document with the latest message info
      await updateDoc(chatRef, {
        lastMessage: newMessage || (imageFile && 'Image') || (file && "File"), // Update with the last text or a placeholder if it's just an image
        lastMessageTimestamp: serverTimestamp(), // Timestamp of the last message
        lastMessageSenderId: userId, // Update with the sender ID of the last message
        status: "open",
        restaurant_name: (restaurant_name && !client_name) && restaurant_name,
        client_name: client_name && client_name
      });
      setUpdate(old => old + 1)
      setNewMessage(''); // Clear message input
      setImageFile(null); // Clear image input
      setImageSrc(null);
      let lastMessage = messages[messages.length - 1];
      let message = `Le compte ${login} (${(restaurant_name && !client_name) ? "Restaurant : " + restaurant_name : "Client : " + client_name}) a envoyé un message au support. (${env.REACT_APP_ENV_TYPE === "ovh" ? "OVH : plateforme.kikleo.fr" : "AWS : plateforme.kikleo.com"})`
      if (lastMessage) {
        let date1 = new Date(lastMessage.timestamp.seconds * 1000 + Math.floor(lastMessage.timestamp.nanoseconds / 1000000))
        let date2 = new Date()
        let dateMs = Math.abs(date2 - date1);
        if (dateMs > 300000 || lastMessage.senderId === "Support" || lastMessage.senderId.includes("admin"))
          sendAlertMessageChat(message)
      }
      else
        sendAlertMessageChat(message)
      let input = document.getElementById('chatInput');
      input.style.height = "auto"
    }
  };

  // Handle image file selection
  const handleImageChange = (e) => {
    setFile(null);
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const handleChangeFile = (e) => {
    setImageSrc(null);
    setImageFile(null);
    const selectedFile = e.target.files[0]; // Get the selected file
    setFile(selectedFile); // Save the file to state
  }
  const removePicture = () => {
    setImageSrc(null);
    setImageFile(null);
  }
  const removeFile = () => {
    setFile(null);
  }

  const isMessageRead = (msg) => {
    if ((msg.readBy && msg.readBy.length > 1) || msg.senderId === "Support")
      return (<div className={userId === msg.senderId ? 'subtitleBoxRight' : 'subtitleBoxLeft'}>{t('components.read')}</div>)
    else
      return <div className={userId === msg.senderId ? 'subtitleBoxRight' : 'subtitleBoxLeft'}>{t('components.unread')}</div>
  }
  const handleButtonCamClick = () => {
    document.getElementById('pictureInput').click(); // Trigger the hidden file input click
  };
  const handleButtonFileClick = () => {
    document.getElementById('fileInput').click(); // Trigger the hidden file input click
  }
  const isMoreThanOneHourDifference = (message1, message2) => {
    if (message1 && message2) {
      let timestamp1 = message1.timestamp
      let timestamp2 = message2.timestamp
      let diffInMilliseconds = 0;
      if (timestamp1 && timestamp2) {
        const date1 = new Date(timestamp1.seconds * 1000 + Math.floor(timestamp1.nanoseconds / 1000000));
        const date2 = new Date(timestamp2.seconds * 1000 + Math.floor(timestamp2.nanoseconds / 1000000));
        // Get the difference in milliseconds
        diffInMilliseconds = Math.abs(date1 - date2);
      }
      // Check if the difference is more than 1 hour (3600000 milliseconds)
      return diffInMilliseconds > 3600000;
    }
    else return false
  };
  const isLessThanOneHourDifference = (message1, message2) => {
    if (message1 && message2) {
      let timestamp1 = message1.timestamp
      let timestamp2 = message2.timestamp
      let diffInMilliseconds = 0;
      if (timestamp1 && timestamp2) {
        const date1 = new Date(timestamp1.seconds * 1000 + Math.floor(timestamp1.nanoseconds / 1000000));
        const date2 = new Date(timestamp2.seconds * 1000 + Math.floor(timestamp2.nanoseconds / 1000000));
        // Get the difference in milliseconds
        diffInMilliseconds = Math.abs(date1 - date2);
      }
      // Check if the difference is more than 1 hour (3600000 milliseconds)
      return diffInMilliseconds < 3600000;
    }
    else return false;
  };

  const mustShowRead = (prevMessage, currentMessage, nextMessage) => {
    if (currentMessage.senderId === userId) {
      if (currentMessage && nextMessage) {
        if (currentMessage.senderId !== nextMessage.senderId)
          return true;
        else
          if (isLessThanOneHourDifference(currentMessage, nextMessage))
            return false;
          else return true;
      }
      else if (prevMessage && currentMessage) {
        if (prevMessage.senderId !== currentMessage.senderId)
          return true;
        else {
          if (isLessThanOneHourDifference(prevMessage, currentMessage) && nextMessage)
            return false;
          else return true;
        }
      }

    }
  }
  if (isChatOpen)
    return (
      <>
        <CustomBlock
          position={"absolute"}
          width="60vh"
          height="auto"
          minHeight="75vh"
          bottom="10%"
          right="5%"
          fontWeight="300"
        //style={{ position: "absolute", width: "60vh", height: "auto", minHeight: "60vh", backgroundColor: "red", bottom: "10%", right: "5%" }}
        >
          <div style={{ height: "10vh", backgroundColor: "#EAEAEA", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", display: "flex" }}>
            <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div className='circleDivChat'>
                <KikleoChatIcon style={{ width: "80%", height: "80%" }} />
              </div>
            </div>
            <div style={{ width: "80%", height: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", color: "#6D6C7A", fontSize: "1.2vw" }}>
              {t('components.supportKikleo')}
              <ChatCross style={{ position: "absolute", right: "2%", top: "2%", cursor: "pointer" }} onClick={() => dispatch(setIsChatOpen(false))} />
            </div>
          </div>
          <div className="chatBox">
            <CustomScrollDiv update={update} scrollToBottom={true}>
              {messages.map((msg, index) => {
                return (<div key={msg.id} className="chatLine" style={{
                  marginTop: (index === 0 || isLessThanOneHourDifference(messages[index - 1], msg)) && "1vh",
                  marginBottom: (index === 0 || isLessThanOneHourDifference(msg, messages[index + 1])) && "1vh"
                }}>
                  {(index === 0 || isMoreThanOneHourDifference(messages[index - 1], msg)) &&
                    <div className='date-div'>
                      {msg.timestamp && t('date', { value: { date: moment(msg.timestamp.toDate()), format: "DD/MM/YYYY HH:mm" } })}
                    </div>
                  }
                  {!msg.imageUrl && !msg.fileUrl && (
                    <div style={{ width: "100%", height: "100%", position: "relative", display: "flex", justifyContent: userId === msg.senderId ? "flex-end" : "flex-start" }}>
                      <div className={userId === msg.senderId ? 'fromMeBubble' : 'fromOtherBubble'} >
                        {msg.text}
                      </div>
                      {mustShowRead(messages[index - 1], msg, messages[index + 1]) && isMessageRead(msg)}
                    </div>)
                  }
                  {msg.imageUrl && !msg.fileUrl && (
                    <div style={{ width: "100%", height: "100%", position: "relative", display: "flex", justifyContent: userId === msg.senderId ? "flex-end" : "flex-start" }}>
                      <div className={userId === msg.senderId ? 'fromMeBubble' : 'fromOtherBubble'} >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <img src={msg.imageUrl} alt="Chat Image" style={{ marginBottom: msg.text && msg.text !== "Image" && "1vh" }} />
                          {msg.text && msg.text !== "Image" && msg.text}
                        </div>
                      </div>
                      {mustShowRead(messages[index - 1], msg, messages[index + 1]) && isMessageRead(msg)}
                    </div>

                  )}
                  {
                    msg.fileUrl && !msg.imageUrl && (
                      <div style={{ width: "100%", height: "100%", position: "relative", display: "flex", justifyContent: userId === msg.senderId ? "flex-end" : "flex-start" }}>
                        <div className={userId === msg.senderId ? 'fromMeBubble' : 'fromOtherBubble'} >
                          <a href={msg.fileUrl} target="_blank" rel="noopener noreferrer">
                            {msg.fileName}
                          </a>
                          {msg.text && msg.text !== "File" && "\n" + msg.text}
                        </div>
                        {mustShowRead(messages[index - 1], msg, messages[index + 1]) && isMessageRead(msg)}
                      </div>
                    )
                  }
                </div>
                )
              })}
            </CustomScrollDiv>
          </div >
          <div className='chatWrite'>
            {imageSrc &&
              <div style={{ width: '95%', position: "absolute", bottom: "102%", backgroundColor: "#fff", border: "1px solid #666", paddingLeft: "1%", borderRadius: "5px" }}>
                <div style={{ position: "relative", display: "inline-block", width: "100%", height: "100%", display: "flex", justifyContent: "center", paddingTop: "1%", paddingBottom: "1%" }}>
                  <img src={imageSrc} alt="Chat Image" style={{ height: 'auto', width: "40%" }} />
                  <Cross className='cross-icon' onClick={() => removePicture()} />
                </div>
              </div>
            }
            {file &&
              <div style={{ width: '95%', position: "absolute", height: "5vh", bottom: "102%", backgroundColor: "#fff", padding: "0%", border: "1px solid #666", paddingLeft: "1%", borderRadius: "5px" }}>
                <div style={{ position: "relative", display: "inline-block", width: "100%", height: "100%", display: "flex", alignItems: "center" }}>
                  {file.name}
                  <Cross className='cross-icon-file' onClick={() => removeFile()} />
                </div>
              </div>
            }
            <div style={{ height: "6vh", width: "100%", }}>
              <div style={{ height: "auto", minHeight: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>

                <textarea
                  ref={textareaRef}
                  type="text"
                  id='chatInput'
                  className='chatInput'
                  value={newMessage}
                  onChange={handleInputChange}
                  rows={1}
                  style={{
                    position: "absolute",
                    bottom: `0px`, // Adjust textarea to grow upwards
                    resize: "none", // Disable manual resizing
                    height: "auto",
                    maxHeight: "20vh"
                  }}
                  placeholder={t('components.enterMessage')}
                />
                {/*<button className="sendButton" onClick={sendMessage}>{t('buttons.send')}</button>*/}
                <SendIcon
                  ref={iconRef} style={{
                    position: 'absolute',
                    cursor: 'pointer',
                    fontSize: '24px',
                    color: '#555',
                    transition: 'top 0.2s, left 0.2s',
                    right: "5%"
                  }} onClick={sendMessage} />
              </div>
            </div>
            <div style={{ height: "4vh", width: "100%" }}>

              <input style={{ display: "none" }} id="pictureInput" accept="image/*" type="file" onChange={handleImageChange} /> {/* File input for image */}
              <PictureChat className='camera-icon'
                onClick={handleButtonCamClick}
              />
              <input style={{ display: "none" }} id="fileInput" type="file" accept=".pptx,.pdf,.docx,.xlsx,.xls,.csv,.doc" onChange={handleChangeFile} /> {/* File input for image */}
              <Clip className='file-icon'
                onClick={handleButtonFileClick}
              />
            </div>

          </div>
        </CustomBlock >

        <div style={{ position: "absolute", right: "2%", bottom: "2%", width: "7vh", height: "7vh", cursor: "pointer" }}
          onClick={() => dispatch(setIsChatOpen(false))}>
          <CrossCircle style={{ width: "100%", height: "100%" }} />
        </div>
      </>
    );
  else {
    return (
      <div style={{ position: "absolute", right: "2%", bottom: "2%", width: "7vh", height: "7vh", cursor: "pointer" }}
        onClick={() => dispatch(setIsChatOpen(true))}>
        {unreadNumber > 0 &&
          <div style={{ color: "#fff", position: "absolute", width: "1.5vw", height: "1.5vw", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "red", top: "-15%", right: "-10%" }}>
            {unreadNumber}
          </div>}
        <Chat style={{ width: "100%", height: "100%", fill: unreadNumber > 0 ? "#B3D693" : "#949494" }} />
      </div>
    )
  }
}

export default ClientChat;
