import React, { useState, useEffect } from 'react';
import CustomScrollDiv from '../../customComponents/CustomScrollBar';
import "./Chat.css";
import CustomBlock from '../../customComponents/CustomBlock';
import { useDispatch } from 'react-redux';
import { setLoggedInChat, setSelectedChatId } from '../../../actions_reducers/actions/admin.action';

import { collection, doc, query, where, getDocs, onSnapshot, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import Firebase storage utilities
import { db, storage } from '../../../firebase'; // Import storage from firebase.js
import { useTranslation } from 'react-i18next';
import CustomButton from '../../customComponents/CustomButton';
const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);

function AdminChatList(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const updatePassword = (e) => {
        if (e.target.value.toString() === "CsmMdp2024*") dispatch(setLoggedInChat(true));
    }
    const updateConversationStatus = async (id) => {
        const chatRef = doc(db, 'chats', id);
        await updateDoc(chatRef, {
            status: "closed",
        });
    }
    const getDateFromTimestamp = (timestamp) => {
        return t('date', { value: { date: moment(timestamp.toDate()), format: "DD/MM/YYYY HH:mm" } })
    }
    const getLastMessage = (item) => {
        if (item.lastMessage === "Image") return "Image";
        else if (item.lastMessage === "File") return "Fichier";
        else return item.lastMessage;
    }
    return (
        <CustomBlock width={"100%"} height={"100%"} paddingTop={"1%"} paddingBottom={"1%"} paddingRight={"1%"} paddingLeft={"1%"}>
            {props.loggedInChat ?
                <CustomScrollDiv update={0}>
                    {
                        props.conversations.filter((a) => a.status === props.selected_type).map((item, index) => {
                            let user = props.userIdsEmails.find(a => a.id === item.id);
                            if (user)
                                return (
                                    <div className="adminChatLine" style={{ backgroundColor: index % 2 === 0 ? "#F0F0F0" : "#fff", fontWeight: props.unreadCounts[item.id] > 0 && "bold" }}
                                        onClick={(e) => e.target.id !== "archiveButton" && dispatch(setSelectedChatId(item.id))}>
                                        <div className="adminChatConv">
                                            {user.email}
                                        </div>
                                        <div className='adminChatLastMessageDate'>
                                            {getDateFromTimestamp(item.lastMessageTimestamp)}
                                        </div>
                                        <div className='adminChatLastMessageText'>
                                            <span>
                                                {(item.lastMessageSenderId === "Support" ? "Vous : " : "") + getLastMessage(item)}
                                            </span>
                                        </div>
                                        <div style={{ width: "15%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <CustomButton id={"archiveButton"} backgroundColor={"#68B4E3A1"} color={"#fff"}
                                                width={"90%"}
                                                height={"80%"}
                                                value={t('buttons.archive')} onClick={() => updateConversationStatus(item.id)} />

                                        </div>
                                    </div>
                                )
                        })
                    }
                </CustomScrollDiv>
                :
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{
                        width: "95%", height: "90%", backgroundColor: "#EAEAEA", borderRadius: "10px", boxShadow: '0px 4px 4px 0px #00000040',
                        display: "flex", justifyContent: "center", alignItems: "center"
                    }}>
                        <div style={{ height: "25%", width: "100%" }}>
                            <div style={{ height: "50%", width: "100%", fontSize: "1.4vw", fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {t('components.enterPassword')}
                            </div>
                            <div style={{ height: "50%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <input
                                    style={{ width: "35%", height: "60%", borderRadius: "10px", border: "none", boxShadow: "none" }}
                                    type="password" onChange={updatePassword} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </CustomBlock>
    );
}

export default AdminChatList;
